import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/pageStyles/_Index.scss"

import BackgroundVideo_0 from "../assets/vids/24934220-night.ogv"
import BackgroundVideo_1 from "../assets/vids/24934220-night.webm"
import BackgroundVideo_2 from "../assets/vids/24934220-night.mp4"
import BackgroundImage from "../assets/pics/24934220-snap.jpg" 


class NotFoundPage extends React.Component {
    render () {
      return (
        <Layout transparentnav>
          <Seo title="404: Seite nicht gefunden" />
          <section className="oneScreen text_huge">
            <video id="indexBackground" poster={BackgroundImage} className="middleScreen" autoPlay loop muted playsInline="playsInline">
              <source src={BackgroundVideo_1} type='video/webm' />
              <source src={BackgroundVideo_0} type='video/ogg; codecs="theora, vorbis"'/>
              <source src={BackgroundVideo_2} type='video/mp4' />
            </video>
            <div id="indexTextBox">
              <h1 className="text_huge infoText">
                <span className="text_giant error"><b>4</b><b>0</b><b>4</b></span> - Seite nicht gefunden<br />
                Da sind Sie wohl falsch abgebogen?
              </h1> 
            </div>
          </section>
        </Layout>
    )
  }
}

export default NotFoundPage